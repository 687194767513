import * as Colyseus from "colyseus.js"; 
import nipplejs from 'nipplejs';
import sha256 from 'crypto-js/sha256'

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '..'
  }

// Generate a unique ID for this user.
// Taken from https://gist.github.com/gordonbrander/2230317
function uid() {
    return (performance.now().toString(36)+Math.random().toString(36)).replace(/\./g,"");
};

let myUniqueId = null;
if (window.localStorage && window.localStorage.getItem('uid')) {
    myUniqueId = window.localStorage.getItem('uid');
}

if (!myUniqueId) {
    myUniqueId = uid();
    if (window.localStorage) {
        window.localStorage.setItem('uid', myUniqueId);
    }
}
if (params['newPlayer'] !== null) {
    myUniqueId = uid();
}
let hashOfUid = sha256(myUniqueId).toString();

// TODO Should default to localhost on development; but be configurable for the production build.
let defaultServer = process.env.NODE_ENV === 'production' ? 'wss://paint-it.app' : 'ws://localhost';
let server = params['server'] ?? defaultServer;
let port = params['port'] ?? '2567';

var client = new Colyseus.Client(`${server}:${port}`);

let room = null;

let current_user_rotation = 0;

// Send player name to room
let name = null;
if (window.localStorage && window.localStorage.getItem('name')) {
    name = window.localStorage.getItem('name');
}
if (params['player']) {
    name = params['player']
}

if (name === null) {
    name = prompt('What is your name?');
    window.localStorage.setItem('name', name);
    if (name === null || name === '') {
        name = 'Player';
    }
} 

client.joinOrCreate("my_room", {name: name, uid: myUniqueId}).then(loaded_room => {
    room = loaded_room;

    let newGameButton = document.getElementById('js-start-game');
    newGameButton.addEventListener('click', e => {
        e.preventDefault();
        room.send('start');
    })

    // Make sure we show the New Game button only in the lobby.
    // We run this as an event, so we don't need to call this every frame. 
    let playerEmojis = ['🙍', '🙍‍♂️', '🙍‍♀️', '🙎', '🙎‍♂️', '🙎‍♀️', '🙅', '🙅‍♂️', '🙅‍♀️', '🙆'];
    let updateStartGameButtonVisbility = (visible) =>{
        newGameButton.style.display = visible ? 'block' : 'none';

        if (!visible) {
            return;
        }

        let playersThatWillStart = 0;
        room.state.players.forEach(player => {
            // Note: we cannot use player.aliveInLobby here. As we only call this directly after 
            // adding the player. And _not_ after any of their attributes update.
            playersThatWillStart++;
        });

        if (playersThatWillStart <= 1) {
            newGameButton.innerHTML = `Start game! Alone ?! 🤷`;
        } else {
            let representation = '';
            for (let i = 0; i < playersThatWillStart; i++) {
                representation += playerEmojis[i % playerEmojis.length];
            }
            
            newGameButton.innerHTML = `Start game with ${representation}`;
        }
    };

    room.state.listen('gameState', (newGameState) => {
        // TODO Make use of shared constants between client and server.
        updateStartGameButtonVisbility(newGameState === 0); // 0 = Lobby 
    })

    room.state.players.onAdd = () => {
        updateStartGameButtonVisbility(room.state);
    };

    room.state.players.onRemove = () => {
        updateStartGameButtonVisbility(room.state);
    };    
}).catch(e => {
    console.log("JOIN ERROR", e);
});

var canvas = document.getElementById("canvas");
var context = canvas.getContext("2d");
canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

const updateCanvasDimensions = () => {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
};
window.addEventListener('orientationchange', updateCanvasDimensions);
window.addEventListener('resize', updateCanvasDimensions);

window.requestAnimationFrame(gameLoop);
function gameLoop() {
    draw();
    window.requestAnimationFrame(gameLoop);
}

let secondsPassed;
let oldTimeStamp;
function gameLoop(timeStamp) {
    secondsPassed = (timeStamp - oldTimeStamp) / 1000;
    oldTimeStamp = timeStamp;

    update(secondsPassed);
    draw();

    window.requestAnimationFrame(gameLoop);
}

let keys = {};
let supportedKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", ' ', "Control", 'b'];

window.addEventListener("keydown", function (event) {
    if (event.defaultPrevented || !supportedKeys.includes(event.key)) {
      return;
    }

    keys[event.key] = true;
    event.preventDefault();
});

window.addEventListener("keyup", function (event) {
    if (event.defaultPrevented || !supportedKeys.includes(event.key)) {
        return;
    }  

    keys[event.key] = false;
    event.preventDefault();
});


let isTouchDevice =  
    ( 'ontouchstart' in window ) ||
    ( navigator.maxTouchPoints > 0 ) ||
    ( navigator.msMaxTouchPoints > 0 );

let joystick_wants_to_drop_bomb = false;
let joystick_wants_to_drop_wall = false;
let joystick_wants_to_move_forward = null;
document.addEventListener('DOMContentLoaded', () => {    
    if (!isTouchDevice) {
        return;
    }
    
    var options = {
        zone: document.getElementById('joystick_left'),
        mode: 'static',
        position: {left: '15%', top: '70%'},
        color: 'red'        
    };
    var manager = nipplejs.create(options);

    manager.on('move', (event, data )=> { 
        joystick_wants_to_move_forward = data.distance / 50.0;
        current_user_rotation = -data.angle.radian;
    });
    manager.on('end', () => {
        joystick_wants_to_move_forward = null;
    });


    var options = {
        zone: document.getElementById('joystick_right'),
        mode: 'static',
        position: {right: '15%', top: '70%'},
        color: 'blue',
        threshold: 0.5
    };
    var manager = nipplejs.create(options);

    manager.on('dir', (event, data)=> { 
        if (data.direction.angle === 'up') {
            joystick_wants_to_drop_bomb = true;
        } else if (data.direction.angle === 'down') {
            joystick_wants_to_drop_wall = true;
        }
    });
});

function update(secondsPassed) {
    if (!room) {
        return;
    }

    if (room.state.gameState == 0 && room.state.step % 25 === 0) {
        room.send('client_alive', {});
    }

    if (keys['b']) {
        room.send('add_bot', {});
        keys['b'] = null;
    }

    let turnSpeed = Math.PI;
    let moveDirection = 0;
    if (keys["ArrowLeft"]) {
        current_user_rotation -= turnSpeed * secondsPassed;
    } else if (keys["ArrowRight"]) {
        current_user_rotation += turnSpeed * secondsPassed;
    }

    if (joystick_wants_to_move_forward !== null) {
        moveDirection = joystick_wants_to_move_forward;
    } else {        
        if (keys["ArrowUp"]) {
            moveDirection = 1.0;
        } else if(keys["ArrowDown"]) {
            moveDirection = -1.0;
        }
    }

    room.send('move', { rotation: current_user_rotation, move_direction: moveDirection });

    if (keys[" "] || joystick_wants_to_drop_bomb) {
        room.send('bomb');
        keys[' '] = false;
        joystick_wants_to_drop_bomb = false;
    }

    if (keys["Control"] || joystick_wants_to_drop_wall) {
        room.send('wall');
        keys['Control'] = false;
        joystick_wants_to_drop_wall = false;
    }
}


function draw_bomb(x, y, colorA, colorB = null, explodes_at = null, bombRadius = 0.01)
{
    if (colorB === null) {
        colorB = colorA;
    }

    let strokeStyle = isDark(colorA) ? '#ffffff' : '#000000';

    let extra = 0;

    if (explodes_at) {
        let progression = (explodes_at - room.state.step) / 500;
        let extraRadius = bombRadius + Math.max(0, (1 - progression) - 0.7) * 0.1;
        extra = Math.sin(75 * Math.pow(1 - progression, 2)) * extraRadius + extraRadius
    }

    // Draw above half of bomb
    context.fillStyle = colorA;
    context.strokeStyle = strokeStyle;
    context.beginPath();
    context.arc(x, y, 0.1 + extra, Math.PI / 4, Math.PI / 4 + Math.PI);
    context.fill();

    context.fillStyle = colorB;
    context.beginPath();
    context.arc(x, y, 0.1 + extra, Math.PI / 4 + Math.PI, Math.PI / 4 + 2 * Math.PI);
    context.fill();

    context.strokeStyle = strokeStyle;
    context.beginPath();
    context.arc(x, y, 0.1 + extra, 0, 2 * Math.PI);
    context.stroke();
}

function draw_powerup_speed(x, y, color) {
    let width = 0.15;
    let height = 0.25;
    context.fillStyle = color;
    context.strokeStyle = '#000000';

    context.beginPath();
    // Top horizontal part
    context.moveTo(x + width * 0.8, y - height);
    context.lineTo(x + width * 0.2, y - height * 0.95);
    // Left
    context.lineTo(x - width, y + height * 0.1);
    context.lineTo(x - width * 0.1, y - height * 0.1);

    // Point below
    context.lineTo(x - width, y + height);

    // Right
    context.lineTo(x + width, y - height * 0.4);
    context.lineTo(x + width * 0.1, y - height * 0.3);

    // Go to starting point
    context.lineTo(x + width * 0.8, y - height);

    context.fill();
    context.stroke();
}

function draw_wall_item(x, y, color) {
    let width = 0.7;
    let height = 0.15;
    context.fillStyle = color;
    context.strokeStyle = '#ffffff';

    context.beginPath();
    context.fillRect(x-width/2, y-height/2, width, height);
    context.fill();
    context.stroke();
}

function isDark(hex) {
    const hexCode = hex.charAt(0) === '#' 
                        ? hex.substr(1, 6)
                        : hex;

    const hexR = parseInt(hexCode.substr(0, 2), 16);
    const hexG = parseInt(hexCode.substr(2, 2), 16);
    const hexB = parseInt(hexCode.substr(4, 2), 16);
    // Gets the average value of the colors
    const contrastRatio = (hexR + hexG + hexB) / (255 * 3);

    return contrastRatio < 0.5;
}

let drawIteration = 0;
function draw() {
    drawIteration++;

    context.setTransform(1, 0, 0, 1, 0, 0);
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);

    if (!room) {
        return;
    }
    
    let translateX = 0;
    let translateY = 0;

    let follow_player = null;
    let current_player = room.state.players.get(hashOfUid);

    if (current_player && (current_player.participates || room.state.gameState == 0)) {
        translateX = current_player.x;
        translateY = current_player.y;
    } else {
        // Follow an active player
        room.state.players.forEach(player => {
            if (!player.participates) {
                return;
            }

            follow_player = player;
        })

        if (follow_player) {
            translateX = follow_player.x;
            translateY = follow_player.y;            
        }
    }


    let scaling = 100;
    if (isTouchDevice) {
        scaling = 65;
    }   

    let gameStarting = room.state.gameState == 1;
    if (gameStarting) {
        let startsIn = room.state.gameStartsIn / 200.0; // TODO 200 is really a copy of the value MyRoomState::StartSteps
        scaling *= Math.pow(1 - startsIn, 2);
    }

    context.setTransform(scaling, 0, 0, scaling, translateX * -scaling + canvas.width / 2, translateY * -scaling + canvas.height / 2);

    let CELL_WIDTH = 1.0;
    let CELL_MARGIN = 0.05;
    let WALL_MARGIN = 0.18;

    room.state.cells.forEach(cell => {
        context.fillStyle = '#eeeeee';
        if (cell.tagged.length > 0) {
            context.fillStyle = `${room.state.players.get(cell.tagged[0]).color}`;
        }

        if (cell.tagged.length <= 1) {
            context.fillRect(cell.x * CELL_WIDTH + CELL_MARGIN, cell.y * CELL_WIDTH + CELL_MARGIN, CELL_WIDTH - CELL_MARGIN * 2, CELL_WIDTH - CELL_MARGIN * 2);
        } else if (cell.tagged.length > 1) {
            let topLeftX = cell.x + CELL_MARGIN;
            let topLeftY = cell.y + CELL_MARGIN;
            let rightBottomX = cell.x + 1 - CELL_MARGIN;
            let rightBottomY = cell.y + 1 - CELL_MARGIN;
        
            let colorA = `${room.state.players.get(cell.tagged[0]).color}`;
            let colorB = `${room.state.players.get(cell.tagged[1]).color}`;

            let stripes = 2;
            let increases = (1 - CELL_MARGIN * 2) / (stripes * 2);
            for (let stripe = 0; stripe < stripes * 2; stripe++) {
                {
                    let startX = topLeftX + increases * stripe;
                    let startY = topLeftY;

                    let endX = rightBottomX;
                    let endY = rightBottomY - increases * stripe;

                    context.fillStyle = stripe % 2 === 0 ? colorA :  colorB;
                    context.beginPath();
                    context.moveTo(startX, startY);
                    context.lineTo(endX, endY);
                    context.lineTo(endX, endY - increases);
                    context.lineTo(startX + increases, startY);
                    context.fill();
                }

                {
                    let startX = topLeftX;
                    let startY = topLeftY + increases * stripe;

                    let endX = rightBottomX - increases * stripe;
                    let endY = rightBottomY;

                    context.fillStyle = stripe % 2 === 1 ? colorA :  colorB;
                    context.beginPath();
                    context.moveTo(startX, startY);
                    context.lineTo(endX, endY);
                    context.lineTo(endX - increases, endY);
                    context.lineTo(startX, startY + increases);
                    context.fill();            
                }
            }
        }
        
        if (cell.wall) {
            context.fillStyle = '#000000';
            context.fillRect(cell.x * CELL_WIDTH + WALL_MARGIN, cell.y * CELL_WIDTH + WALL_MARGIN, CELL_WIDTH - WALL_MARGIN * 2, CELL_WIDTH - WALL_MARGIN * 2);            
        }

        let cell_x = cell.x + CELL_WIDTH/2;
        let cell_y = cell.y + CELL_WIDTH/2;
        if (cell.item == 1) {
            draw_bomb(cell_x , cell_y, '#000000');
        }
        else if (cell.item == 2) {
            draw_wall_item(cell_x , cell_y, '#000000');
        }
        else if (cell.item == 3) {
            draw_powerup_speed(cell_x , cell_y, '#ffcc00');
        }
    })

    // This shit is so uggly, my father makes me wear a hat.    
    room.state.cells.forEach(cell => {
        if (!cell.teleport_in && !cell.teleport_out) {
            return;
        }

        let numberOfCircles = 5;
        let colors = ['080808', '676767', '808080', 'a9a9a9', 'bebebe', 'dddddd'];
        let direction = cell.teleport_in ? 1 : -1;
        for (let i = 0; i < numberOfCircles; i++) {
            let anim = drawIteration;
            if (cell.teleport_in) {
                anim = -drawIteration;
            }

            let radius = Math.abs(1 / numberOfCircles * i + ((anim / 2) % 10) / 50);

            let colorIndex = Math.floor(1.0 / radius);
            if (cell.teleport_out) {
                colorIndex = colors.length - colorIndex;
            }

            let color = colors[colorIndex];

            context.strokeStyle = `#${color}`;
            context.beginPath();
            context.arc(cell.x + 0.5, cell.y + 0.5, radius, 0, 2 * Math.PI);
            context.stroke();
        }
    });

    room.state.bombs.forEach(bomb => {
        let colorA = room.state.players.get(bomb.player).color;
        let colorB = bomb.joinedPlayer ? room.state.players.get(bomb.joinedPlayer).color : null;
        
        draw_bomb(bomb.x, bomb.y, colorA, colorB, bomb.explodes_at, 0.05);
    });


    room.state.players.forEach(player => {
        if (!player.participates) {
            return;
        }

        let playerStunned = player.stunned_until >= room.state.step;

        let rotation = player.rotation;
        if (player.hashOfUid === hashOfUid) {
            rotation = current_user_rotation;
        } 

        context.fillStyle = player.color;
        context.strokeStyle = isDark(player.color) ? '#ffffff' : '#000000';

        // Body properties
        let bodyRadius = 0.30 + 0.10 * player.penalty;
        let armRadius = 0.09;
        let headRadius = 0.12;
        let noseLength = 0.05;
        context.lineWidth = 0.02;

        // Draw body
        context.beginPath();
        context.arc(player.x, player.y, bodyRadius, 0, 2 * Math.PI);
        context.fill();
        context.stroke();

        // Draw head
        let dX = headRadius * Math.sin(rotation);
        let dY = headRadius * Math.cos(rotation);

        context.beginPath();
        context.arc(player.x, player.y, headRadius, 0, 2 * Math.PI);
        context.fill();
        context.stroke();

        context.beginPath();
        context.moveTo(player.x + Math.cos(rotation) * headRadius, player.y + Math.sin(rotation) * headRadius);
        context.lineTo(player.x + Math.cos(rotation) * (headRadius+noseLength), player.y + Math.sin(rotation) * (headRadius+noseLength));
        context.fill();
        context.stroke();

        // Draw arms
        let animationOffset = 0;
        if (playerStunned) {
            animationOffset = drawIteration / 20 - rotation; // We substract rotation, so player remains stil while stunned.
        }

        let deltaX = bodyRadius * Math.sin(rotation + animationOffset);
        let deltaY = bodyRadius * Math.cos(rotation + animationOffset);

        let armWalkingOffsetDirectionX = 0;
        let armWalkingOffsetDirectionY = 0;
        if (Math.abs(player.move_direction) > 0)
        {
            let armWalkingoffset = Math.sin(drawIteration/2) * 0.07 * player.move_direction;
            armWalkingOffsetDirectionX = Math.cos(rotation) * armWalkingoffset;
            armWalkingOffsetDirectionY = Math.sin(rotation) * armWalkingoffset;
        }

        context.beginPath();
        context.arc(player.x + deltaX + armWalkingOffsetDirectionX, player.y - deltaY + armWalkingOffsetDirectionY, armRadius, 0, 2 * Math.PI);
        context.fill();
        context.stroke();
        context.beginPath();
        context.arc(player.x - deltaX - armWalkingOffsetDirectionX, player.y + deltaY - armWalkingOffsetDirectionY, armRadius, 0, 2 * Math.PI);
        context.fill();
        context.stroke();
    });


    // fillText can not draw font sizes of smaller than 1px. But because we transform the viewport, this _is_ required.
    // To remedy this, we temporary scale the viewport way up, so we can draw small player names.
    let scaling_factor = 100; // Arbitrary large enough value.

    // context.setTransform(scaling, 0, 0, scaling, translateX * -scaling + canvas.width / 2, translateY * -scaling + canvas.height / 2);
    context.transform(1.0 / scaling_factor, 0, 0, 1.0 / scaling_factor, 0, 0);

    // let new_scaling = scaling * 1 / scaling_factor;
    // context.setTransform(new_scaling, 0, 0, new_scaling, translateX * -new_scaling + canvas.width * scaling_factor / 2, translateY * -new_scaling + canvas.height * scaling_factor / 2);

    room.state.players.forEach(player => {
        if (!player.participates || player === current_player) {
            return;
        }
        
        context.textAlign = "left"; 
        context.font = '34px serif';
        context.fillStyle = `#555`;
        context.fillText(player.name, (player.x + 0.3) * scaling_factor, (player.y - 0.5) * scaling_factor);
    });

    // Draw the UI stuff
    context.setTransform(1, 0, 0, 1, 0, 0);

    // Compute scores
    let playersSortedOnScore = [];
    room.state.players.forEach(player => {
        if (player.participates) {
            playersSortedOnScore.push(player);
        }
    });
    playersSortedOnScore.sort((p1, p2) => p1.score - p2.score);
    let myPosition = playersSortedOnScore.indexOf(current_player);
    let playerAboveMe = myPosition === playersSortedOnScore.length - 1 ? null : playersSortedOnScore[myPosition + 1];
    if (playerAboveMe && playerAboveMe.score === current_player.score) {
        playerAboveMe = null;
    }

    let playerBelowMe = myPosition === 0 ? null : playersSortedOnScore[myPosition - 1];
    if (playerBelowMe && playerBelowMe.score === current_player.score) {
        playerBelowMe = null;
    }

    context.textAlign = "left"; 

    let secondsLeft = (Math.floor((room.state.gameSteps - room.state.step) / 100));

    let me = room.state.players.get(hashOfUid);
    let numberOfBombs = me ? room.state.players.get(hashOfUid).number_of_bombs : 0;
    let numberOfWalls = me ? room.state.players.get(hashOfUid).number_of_walls : 0;
    let myScore = me ? room.state.players.get(hashOfUid).score : 0;
    let myName = me ? room.state.players.get(hashOfUid).name : 'Score';

    if (!isTouchDevice) {
        if (room.state.gameState > 0) {
            let margin = 20;
            let rowSize = 40;
            let width = 400;
            let colorBoxSize = 15;

            let playerCount = 0;
            room.state.players.forEach(player => {
                if (player.participates) {
                    playerCount++
                }
            });

            context.fillStyle = '#eeeeee';
            context.fillRect(0, 0, width, margin * 4 + playerCount * rowSize);

            context.font = '20px serif';
            context.fillStyle = '#000000';
            context.fillText('Time left: ' + secondsLeft, margin, margin + 15);

            context.fillStyle = '#000000';
            
            {
                let startX = margin;
                let indicatorY = margin + margin + margin;
                let indicatorSize = 15;
                for (let i = 0; i < numberOfBombs; i++) {
                    context.beginPath();
                    context.arc(startX + 8, indicatorY, 6, 0, 2 * Math.PI);
                    context.fill();
                    context.stroke();
                    startX += indicatorSize + 5;
                }
            }

            {
                let startX = 3 + margin;
                let indicatorY = margin + margin + margin;
                let indicatorSize = 25;
                for (let i = 0; i < numberOfWalls; i++) {
                    context.fillRect(startX, margin + indicatorY, 13, 10);
                    startX += indicatorSize;
                }
            }

            let currentY = margin + margin + margin + margin + margin;
            room.state.players.forEach(player => {
                if (!player.participates) {
                    return;
                }

                context.fillStyle = `${player.color}`;
                context.fillRect(margin, currentY, colorBoxSize, colorBoxSize);

                context.font = '20px serif';
                context.fillStyle = '#000000';
                context.fillText(player.name + ': ' + player.score, margin + colorBoxSize + margin, currentY + 15);
                currentY += rowSize;
            });
        }

        context.fillStyle = '#000000';
        context.font = '40px serif';
        context.textAlign = "center"; 
        if (room.state.gameState == 0) {
            drawStrokedText(context, 'In lobby. Waiting for game to start.', canvas.width / 2, canvas.height / 2, '#000000');
        } else if (room.state.gameState == 3) {
            // TODO Should probably store this in the state; as players can leave.
            let players = [];
            room.state.players.forEach(player => {
                if (player.participates) {
                    players.push(player);
                }
            });
            players.sort((p1, p2) => p2.score - p1.score);
            drawStrokedText(context, 'Game Over! ' + players[0].name + ' Won!', canvas.width / 2, canvas.height / 2, '#000000');
        } else if (room.state.gameState == 1) {
            drawStrokedText(context, 'Game is starting in ' + room.state.gameStartsIn, canvas.width / 2, canvas.height / 2, '#000000');
        }                
    } else {
        // Draw game over score screen on mobile.
        if (room.state.gameState == 3) {
            context.fillStyle = '#000000';
            context.fillRect(0, 0, canvas.width, canvas.height); 

            let players = [];
            room.state.players.forEach(player => {
                if (!player.participates) {
                    return;
                }
                
                players.push({
                    name: player.name,
                    score: player.score,
                    color: player.color,
                });
            });

            players.sort((p1, p2) => p2.score - p1.score);

            let startFontSize = 90;
            let currentX = canvas.width / 2;
            let currentY = startFontSize;
        
            context.textAlign = 'center'; 
            players.forEach(player => {
                context.font = `${startFontSize}px serif`;

                context.fillStyle = `${player.color}`;
                context.fillText(`${player.name} ${player.score}`, currentX, currentY);
                currentY += startFontSize;
                startFontSize *= 0.7;
            });
        } else {
            // Draw a top bar on top of the screen.
            let topBar = 30;
            context.fillStyle = '#000000';
            context.fillRect(0, 0, canvas.width, topBar);

            context.font = '19px serif'
            context.fillStyle = '#ffffff';

            if (room.state.gameState > 0 && follow_player === null) {
                // Time left
                context.textAlign = 'left';            
                context.fillText(`Time = ${secondsLeft}`, 9, topBar - 9);

                // Number of bombs
                let bombIndicatorX = canvas.width - 80;
                let bombIndicatorY = topBar / 2;
                let bombIndicatorWidth = 10;

                context.fillStyle = '#ffffff';
                context.beginPath();
                context.arc(bombIndicatorX, bombIndicatorY, bombIndicatorWidth, 0, Math.PI * 2);
                context.fill();

                context.fillText(`= ${numberOfBombs}`, canvas.width - 60, topBar - 9);

                // Number of walls
                let wallIndicatorHeight = 10;
                let wallIndicatorX = canvas.width - 180;
                let wallIndicatorY = topBar / 2 - wallIndicatorHeight / 2;

                context.fillStyle = '#ffffff';
                context.beginPath();
                context.fillRect(wallIndicatorX, wallIndicatorY, 30, 10);
                context.fill();

                context.fillText(`= ${numberOfWalls}`, canvas.width - 140, topBar - 9);        

                // Draw own score
                let textY = topBar - 9;
                let textX = canvas.width / 2;

                context.font = 'bold 19px serif'
                context.fillStyle = current_player.color;
                context.textAlign = 'center';
                let text = `${truncateString(myName, 13)} [${myScore}]`
                context.fillText(text, textX, textY);
                let textWidth = context.measureText(text).width;


                context.font = '19px serif'
                if (playerBelowMe) {
                    context.fillStyle = playerBelowMe.color;
                    context.textAlign = 'left';
                    context.fillText(`> ${truncateString(playerBelowMe.name, 13)} [${playerBelowMe.score}]`, textX + textWidth / 2 + 10, textY);
                }

                if (playerAboveMe) {
                    context.fillStyle = playerAboveMe.color;
                    context.textAlign = 'right';
                    context.fillText(`${truncateString(playerAboveMe.name, 13)} [${playerAboveMe.score}] >`, textX - textWidth / 2 - 10, textY);
                }
            } else {
                context.textAlign = "center"; 

                let textY = topBar - 9;
                let textX = canvas.width / 2;

                if (room.state.gameState === 2 && follow_player) {
                    context.fillText(`Waiting for new game to start. Following ${follow_player.name}.`, textX, textY);
                } else if (room.state.gameState == 0) {
                    context.fillText('In lobby. Waiting for somebody to start the game.', textX, textY);
                } else if (room.state.gameState == 3) {
                    // TODO Should probably store this in the state; as players can leave.
                    let players = [];
                    room.state.players.forEach(player => {
                        if (player.participates) {
                            players.push(player);
                        }                     
                    });
                    players.sort((p1, p2) => p2.score - p1.score);
                    context.fillText('Game Over! ' + players[0].name + ' Won!', textX, textY);
                } else if (room.state.gameState == 1) {
                    context.fillText('Game is starting in ' + room.state.gameStartsIn, textX, textY);
                }        
            } 

            // Draw messages
            context.font = '19px serif'
            context.textAlign = 'right';
            context.fillStyle = '#000000';

            let startY = topBar + 30;
            room.state.messages.forEach(message => {
                drawStrokedText(context, message.message, canvas.width - 13, startY, '#000000');
                startY += 30;
            });
        }
    }
}


function drawStrokedText(context, text, x, y, color, strokeSize = 4)
{
    // using the solutions from @Simon Sarris and @Jackalope from
    // https://stackoverflow.com/questions/7814398/a-glow-effect-on-html5-canvas
    context.save();
    context.strokeStyle = isDark(color) ? '#ffffff' : '#000000';
    context.fillStyle = color;
    context.lineWidth = strokeSize;
    context.lineJoin="round";
    context.miterLimit=2;
    context.strokeText(text, x, y);
    context.fillText(text, x, y);
    context.restore();
}